<template>
  <div class="elite-tabs-wrapper-content">
    <div class="resume-list-title">
      <h2>{{ `${customer.customer_name}` }}</h2>
    </div>
    <div class="resume-list">
      <div class="resume-list-inner">
        <dl class="width_1-2" v-if="false">
          <dt>
            {{ $t("COMMON.TAGS") }}
          </dt>
          <dd>
            <tags :tags="customer.tags" @tagsUpdated="customerUpdated" />
          </dd>
        </dl>

        <!--<dl
          class="width_1-2"
          v-if="customer.customer_type == CUSTOMER_TYPE_INDIVIDUAL"
        >
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ customer.firstname }}
          </dd>
        </dl>
        <dl
          class="width_1-2"
          v-if="customer.customer_type == CUSTOMER_TYPE_INDIVIDUAL"
        >
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ customer.lastname }}
          </dd>
        </dl>-->

        <dl v-if="customer.customer_type == CUSTOMER_TYPE_COMPANY">
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ customer.company_name }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ customer.email }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.PHONE") }}</dt>
          <dd>
            {{ customer.phone }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ customer.country }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ customer.state }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ customer.city }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ customer.zipcode }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ customer.address }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="customer.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="customer.organization" />
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(customer.created_at) }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(customer.updated_at) }}
          </dd>
        </dl>

        <div class="col-12 mb-3 mt-2">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <billing-informations-view
          :billingInformations="customer"
          :type="customer.customer_type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_COMPANY,
} from "@/constants/customers";
import BillingInformationsView from "@/components/BillingInformationsView.vue";
import Tags from "@/components/Tags.vue";

export default {
  name: "customer-view-global",

  components: { Tags, BillingInformationsView },

  props: ["customer"],

  data() {
    return {
      CUSTOMER_TYPE_COMPANY: CUSTOMER_TYPE_COMPANY,
      CUSTOMER_TYPE_INDIVIDUAL: CUSTOMER_TYPE_INDIVIDUAL,
    };
  },

  computed: {},

  created() {},

  methods: {
    customerUpdated() {
      this.$emit("customerUpdated", true);
    },
  },

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
